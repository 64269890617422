@import './color';

$theme-colors: (
    "primary": $purple,
    "dark": $black
);

$grid-gutter-width: 0 !default;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";